






































































































































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';

@Component
export default class MissingInformation extends Vue {
    get user() {
        return agentModule.agent;
    }

    get informationIsMissing() {
        return (
            agentModule.informationIsMissing ||
            (!agentModule.informationIsMissing && this.isBroker)
        );
    }

    get isBroker() {
        return agentModule.rol === 'broker';
    }

    get account() {
        return this.user?.account;
    }

    get commercialDataIsMissing() {
        return agentModule.commercialDataIsMissing;
    }

    get personalDataIsMissing() {
        return agentModule.personalDataIsMissing;
    }
}
